export enum Company {
    SKYAUTOKOOL = 2,
    LOPPASTME = 1,
    SMART_AUTOKOOL = 3,
    SKY_BEST_DS = 4,
    SKY_LASNAMAE = 5,
    SIMPLE_AUTOKOOL = 7,
    EXPRESS_AUTOKOOL = 8
}

export enum EventFilterType {
    LANGUAGE = 'LANGUAGE',
    CATEGORY = 'CATEGORY',
    LOCATION = 'LOCATION',
    GEARBOX = 'GEARBOX'
}

export enum Language {
    RUS = 'RUS',
    EST = 'EST',
    ENG = 'ENG'
}

export enum Category {
    A = 'A',
    A1 = 'A1',
    A2 = 'A2',
    AM = 'AM',
    AALL = 'A-all',
    B = 'B',
    BE = 'BE'
}

export enum Gearbox {
    MANUAL = 'MANUAL',
    AUTOMAT = 'AUTOMAT',
    MANUAL_DRIVING_AUTOMAT = 'MANUAL_DRIVING_AUTOMAT'
}

export enum RegistrationFormType {
    FULL = 'FULL',
    BY_PERSONAL_CODE = 'BY_PERSONAL_CODE',
    BY_PERSONAL_CODE_THEN_FULL = 'BY_PERSONAL_CODE_THEN_FULL',
    DRIVING_LESSON = 'DRIVING_LESSON'
}
