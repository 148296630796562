import { determineLocale, Locale } from '@/config/i18n';
import MainLayout from '@/layouts/MainLayout.vue';
import { Company } from '@/types/enums';
import { setLocale } from '@/utils/localStorageUtils';
import Groups from '@/views/Groups.vue';
import SelectType from '@/views/SelectType.vue';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

Vue.use(VueRouter);

function determineCompanyByHost(): Company {
    if (process.env.NODE_ENV !== 'production') {
        return Company.SKYAUTOKOOL;
    }

    switch (location.host) {
        case 'registration.skyautokool.ee':
            return Company.SKYAUTOKOOL;
        case 'registration.libedasoit.ee':
            return Company.LOPPASTME;
        case 'registration.simpleautokool.ee':
            return Company.SIMPLE_AUTOKOOL;
        case 'registration.expressautokool.ee':
            return Company.EXPRESS_AUTOKOOL;
        default:
            return Company.SKYAUTOKOOL;
    }
}

const routes: RouteConfig[] = [
    {
        path: '/:locale',
        component: MainLayout,
        props: {
            company: determineCompanyByHost()
        },
        redirect: '/:locale/select-type',
        children: [
            {
                path: 'groups/:studyTypeIdAndName',
                name: 'study-groups',
                component: Groups,
                props: (route: Route) => ({
                    company: determineCompanyByHost(),
                    studyTypeIdAndName: route.params.studyTypeIdAndName
                })
            },
            {
                path: 'select-type',
                name: 'select-type',
                component: SelectType,
                props: () => ({
                    company: determineCompanyByHost()
                })
            },
            {
                path: '*',
                redirect: '/:locale/select-type'
            }
        ]
    },

    {
        path: '*',
        redirect: {
            name: 'select-type',
            params: {
                locale: determineLocale()
            }
        }
    }
];

const router: VueRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

function locale(fullPath: string): string {
    if (fullPath.includes('/ru/')) {
        return 'ru';
    }
    if (fullPath.includes('/en/')) {
        return 'en';
    }
    return 'et';
}

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const fullPath: string = window.location.href;

    if (fullPath.includes('/courses') && fullPath.includes('type=13')) {
        window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/13-school-exam`);
    }

    else if (fullPath.includes('/courses') && fullPath.includes('type=4')) {
        window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/4-first-aid`);
    }

    else if (fullPath.includes('/courses') && fullPath.includes('type=6')) {
        window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/6-slippery-driving`);
    }

    else if (fullPath.includes('/courses') && fullPath.includes('type=15')) {
        window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/15-night-driving`);
    }

    else if (fullPath.includes('/exams')) {
        window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/25-transport-administration-exams`);
    }

    else if (fullPath.includes('/lasnamae')) {
        window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/24-driving-lessons`);
    }

    else if (fullPath.includes('/driving-practice')) {
        if (fullPath.includes('category=A-all')) {
            window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/24-driving-lessons?category=A-all`);
        }

        else if (fullPath.includes('category=B')) {
            window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/24-driving-lessons?category=B`);
        }

        else {
            window.location.replace(`https://${location.host}/${locale(fullPath)}/groups/24-driving-lessons`);
        }
    }

    next();
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.params.locale === Locale.ET || to.params.locale === Locale.RU || to.params.locale === Locale.EN) {
        setLocale(to.params.locale);
        next();
    } else if (to.params.locale === 'est') {
        next({
            name: <string | undefined>to.name,
            params: {
                locale: Locale.ET
            }
        });
    } else if (to.params.locale === 'rus') {
        next({
            name: <string | undefined>to.name,
            params: {
                locale: Locale.RU
            }
        });
    } else if (to.params.locale === 'eng') {
        next({
            name: <string | undefined>to.name,
            params: {
                locale: Locale.EN
            }
        });
    } else {
        next({
            name: <string | undefined>to.name,
            params: {
                locale: determineLocale()
            }
        });
    }
});

export default router;
