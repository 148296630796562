















































































































































































import NoSuitableFound from '@/components/NoSuitableFound.vue';
import Notification from '@/components/utils/Notification.vue';
import { determineLocale, Locale } from '@/config/i18n';
import Commons from '@/mixins/Commons';
import CompanySpecific from '@/mixins/CompanySpecific';
import { registrationState } from '@/store/modules/registration';
import { setLocale } from '@/utils/localStorageUtils';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component({
    components: { NoSuitableFound, Notification }
})
export default class MainLayout extends Mixins(Commons, CompanySpecific) {

    @Prop({ type: Boolean, default: true })
    public readonly showHeader!: boolean;

    get showMenu(): boolean {
        return registrationState.showMenu;
    }

    mounted() {
        this.setInitialLocale();
        registrationState.getStudyTypes(this.company);
        registrationState.getLocations();
    }

    private setInitialLocale() {
        const locale: Locale = determineLocale();
        this.$root.$i18n.locale = locale;
        this.$vuetify.lang.current = locale;
    }

    private switchLocale(locale: Locale) {
        this.$root.$i18n.locale = locale;
        this.$vuetify.lang.current = locale;
        setLocale(locale);

        try {
            this.$router.push({
                name: this.$route.name ?? undefined,
                params: { locale }
            });
            // eslint-disable-next-line no-empty
        } catch {}
    }
}
