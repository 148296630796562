import { getLocations, getStudyGroups, getStudyTypes } from '@/api/registration';
import store from '@/store';
import { LocationResponse, StudyGroupResponse, StudyGroupSearchRequest, StudyTypeResponse } from '@/types';
import { Company } from '@/types/enums';
import { AxiosResponse } from 'axios';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface RegistrationVuexState {
    studyGroups: StudyGroupResponse[];
    studyTypes: StudyTypeResponse[];
    locations: LocationResponse[];
    currentStudyTypeId: number | undefined;
    isLoading: boolean;
}

@Module({
    name: 'registration',
    namespaced: true,
    dynamic: true,
    store
})
class Registration extends VuexModule implements RegistrationVuexState {

    public studyGroups: StudyGroupResponse[] = [];
    public studyTypes: StudyTypeResponse[] = [];
    public locations: LocationResponse[] = [];
    public currentStudyTypeId: number | undefined = undefined;

    public isLoading: boolean = false;
    public showMenu: boolean = true;

    get studyTypeById(): (studyTypeId: number) => StudyTypeResponse | undefined {
        return (studyTypeId: number) => {
            return this.studyTypes.find((studyType: StudyTypeResponse) => {
                return studyType.id === studyTypeId;
            });
        };
    }

    get currentStudyType(): StudyTypeResponse | undefined {
        if (this.currentStudyTypeId === undefined) {
            return undefined;
        }

        return this.studyTypes.find((studyType: StudyTypeResponse) => {
            return studyType.id === this.currentStudyTypeId!;
        });
    }

    get visibleStudyTypes(): StudyTypeResponse[] {
        return this.studyTypes.filter((studyType: StudyTypeResponse) => studyType.isVisible);
    }

    @Mutation
    public setStudyGroups(studyGroups: StudyGroupResponse[]) {
        this.studyGroups = studyGroups;
    }

    @Mutation
    public setStudyTypes(studyTypes: StudyTypeResponse[]) {
        this.studyTypes = studyTypes;
    }

    @Mutation
    public setLocations(locations: LocationResponse[]) {
        this.locations = locations;
    }

    @Mutation
    public setCurrentStudyType(currentStudyTypeId: number | undefined) {
        this.currentStudyTypeId = currentStudyTypeId;
    }

    @Mutation
    public setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @Mutation
    public setShowMenu(showMenu: boolean) {
        this.showMenu = showMenu;
    }

    @Action
    public async getStudyGroups(studyGroupSearchRequest: StudyGroupSearchRequest) {
        this.setIsLoading(true);
        try {
            const response: AxiosResponse<StudyGroupResponse[]> = await getStudyGroups(studyGroupSearchRequest);
            this.setStudyGroups(response.data);
        } finally {
            this.setIsLoading(false);
        }
    }

    @Action
    public async getStudyTypes(companyId: Company) {
        try {
            const response: AxiosResponse<StudyTypeResponse[]> = await getStudyTypes(companyId);
            this.setStudyTypes(response.data);
            // eslint-disable-next-line no-empty
        } catch {}
    }

    @Action
    public async getLocations() {
        try {
            const response: AxiosResponse<LocationResponse[]> = await getLocations();
            this.setLocations(response.data);
            // eslint-disable-next-line no-empty
        } catch {}
    }
}

export const registrationState: Registration = getModule(Registration);
