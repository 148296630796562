















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Notification extends Vue {

    private isRegistrationNotification(item: NotificationOptions): boolean {
        return item?.data?.isRegistration;
    }
}
