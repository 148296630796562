import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DateUtils extends Vue {

    public formatIsoDate(date?: string | null): string | null {
        if (!date) return null;
        date = this.trimToIsoLocalDate(date);

        const [year, month, day]: string[] = date.split('-');
        return `${day}.${month}.${year}`;
    }

    public formatIsoTime(time?: string | null): string | null {
        if (!time) return null;
        time = this.trimToIsoLocalTime(time);

        const [hours, minutes, seconds]: string[] = time.split(':');
        return `${hours}:${minutes}:${seconds}`;
    }

    public formatIsoTimeNoSeconds(time?: string | null): string | null {
        if (!time) return null;
        time = this.trimToIsoLocalTime(time);

        const [hours, minutes, seconds]: string[] = time.split(':');
        return `${hours}:${minutes}`;
    }

    public formatIsoDateTime(dateTime?: string | null): string | null {
        if (!dateTime) return null;
        dateTime = this.trimToIsoLocalDateTime(dateTime);

        const [date, time]: string[] = dateTime.split('T');
        return `${this.formatIsoDate(date)} ${this.formatIsoTime(time)}`;
    }

    public formatIsoDateTimeNoSeconds(dateTime?: string | null): string | null {
        if (!dateTime) return null;
        dateTime = this.trimToIsoLocalDateTime(dateTime);

        const [date, time]: string[] = dateTime.split('T');
        return `${this.formatIsoDate(date)} ${this.formatIsoTimeNoSeconds(time)}`;
    }

    public trimToIsoLocalDate(date: string): string {
        date = this.trimToIsoLocalDateTime(date);
        if (date.includes('T')) {
            date = date.substr(0, date.indexOf('T'));
        }
        return date;
    }

    public trimToIsoLocalTime(time: string): string {
        time = this.trimToIsoLocalDateTime(time);
        if (time.includes('.')) {
            time = time.substr(0, time.indexOf('.'));
        }
        if (time.includes('T')) {
            time = time.substr(time.indexOf('T') + 1);
        }
        return time;
    }

    public trimToIsoLocalDateTime(dateTime: string): string {
        if (dateTime.includes('+')) {
            dateTime = dateTime.substr(0, dateTime.indexOf('+'));
        }
        if (dateTime.includes('Z')) {
            dateTime = dateTime.substr(0, dateTime.indexOf('Z'));
        }
        return dateTime;
    }

    public formatToUtcTime(dateTime: Date): string {
        const zeroPadding = (number: number) => String(number).padStart(2, '0');
        const hours: number = dateTime.getUTCHours();
        const minutes: number = dateTime.getUTCMinutes();
        const seconds: number = dateTime.getUTCSeconds();

        return `${zeroPadding(hours)}:${zeroPadding(minutes)}:${zeroPadding(seconds)}`;
    }
}
