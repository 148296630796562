import { gearboxes } from '@/config/constants';
import { registrationState } from '@/store/modules/registration';
import { StudyGroupResponse, TranslationValueTextFunction } from '@/types';
import { Category } from '@/types/enums';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class RegistrationFormMixin extends Vue {

    @VModel({ type: Boolean, required: true })
    protected dialog!: boolean;

    @Prop({ required: true })
    protected readonly event: StudyGroupResponse | null;

    protected isSubmitting: boolean = false;

    get gearboxes(): TranslationValueTextFunction[] {
        return gearboxes;
    }

    get isACategory(): boolean {
        return this.event?.category === Category.A
            || this.event?.category === Category.A1
            || this.event?.category === Category.A2
            || this.event?.category === Category.AM
            || this.event?.category === Category.AALL;
    }

    get isFinalStageLSGroup(): boolean {
        return registrationState.currentStudyType?.isFinalStageLS ?? false;
    }
}
