




























import Commons from '@/mixins/Commons';
import CompanySpecific from '@/mixins/CompanySpecific';
import { registrationState } from '@/store/modules/registration';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class SelectType extends Mixins(Commons, CompanySpecific) {

    mounted() {
        registrationState.setShowMenu(false);
    }
}
