



































































































































































import EventCard from '@/components/EventCard.vue';
import RegistrationByPersonalCodeFormDialog from '@/components/forms/RegistrationByPersonalCodeFormDialog.vue';
import RegistrationFullFormDialog from '@/components/forms/RegistrationFullFormDialog.vue';
import RegistrationToDrivingLessonFormDialog from '@/components/forms/RegistrationToDrivingLessonFormDialog.vue';
import Loading from '@/components/utils/Loading.vue';
import { allACategories } from '@/config/constants';
import Commons from '@/mixins/Commons';
import Filters from '@/mixins/Filters';
import { registrationState } from '@/store/modules/registration';
import { LocationResponse, StudyGroupResponse, StudyTypeResponse, TranslationValueTextFunction } from '@/types';
import { Category, Company, EventFilterType, RegistrationFormType } from '@/types/enums';
import { head } from 'lodash';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: { RegistrationToDrivingLessonFormDialog, RegistrationByPersonalCodeFormDialog, RegistrationFullFormDialog, Loading, EventCard }
})
export default class Groups extends Mixins(Commons, Filters) {

    private selectedEvent: StudyGroupResponse | null = null;
    private studyTypeIdInFilter: number | null = null;
    private showFullForm: boolean = false;
    private personalCode: string | null;

    private menu: boolean = false;

    @Prop({ type: String, required: true })
    private readonly studyTypeIdAndName!: string;

    @Prop({ type: Number, required: true })
    private readonly company!: Company;

    get studyTypeId(): number {
        const match: string | undefined = head(/^\d+/.exec(this.studyTypeIdAndName));
        return match ? parseInt(match) : NaN;
    }

    get studyType(): StudyTypeResponse | undefined {
        return registrationState.studyTypeById(this.studyTypeId);
    }

    get studyTypeLabel(): string | undefined {
        if (this.studyType) {
            return this.getLabelByLocale(this.studyType);
        }
        return undefined;
    }

    get studyTypeRegistrationForm(): RegistrationFormType | undefined {
        return this.studyType?.registrationForm;
    }

    get companyIdsToFetchGroupsFor(): number[] {
        if (this.company === Company.SKYAUTOKOOL) {
            return [Company.SKYAUTOKOOL, Company.SMART_AUTOKOOL, Company.SKY_BEST_DS, Company.SKY_LASNAMAE];
        }
        return [this.company];
    }

    get studyGroups(): StudyGroupResponse[] {
        return registrationState.studyGroups;
    }

    get locations(): LocationResponse[] {
        return registrationState.locations.filter((location: LocationResponse) => location.studyTypes.map((studyType: StudyTypeResponse) => studyType.id).includes(this.studyTypeId));
    }

    get categoriesFiltered(): TranslationValueTextFunction[] {
        let finalCategoriesList: TranslationValueTextFunction[];

        if (this.company === Company.SIMPLE_AUTOKOOL) {
            finalCategoriesList = this.categories.filter((category: TranslationValueTextFunction) => {
                return category.value === Category.B
                    || category.value === Category.A
                    || category.value === Category.A2;
            });
        } else {
            finalCategoriesList = this.visibleSkyAutokoolCategories;
        }

        if (this.filters.category && !finalCategoriesList.map((category: TranslationValueTextFunction) => category.value).includes(this.filters.category)) {
            const missingCategory: TranslationValueTextFunction = this.categories.filter((category: TranslationValueTextFunction) => this.filters.category!.toString() === category.value)[0];
            finalCategoriesList.push(missingCategory);
        }
        return finalCategoriesList;
    }

    get filteredStudyGroups(): StudyGroupResponse[] {
        return this.studyGroups.filter((group: StudyGroupResponse) => {
            if (this.isFilterEnabled(EventFilterType.LANGUAGE)) {
                if (this.filters.language && group.language && this.filters.language !== group.language) {
                    return false;
                }
            }

            if (this.isFilterEnabled(EventFilterType.LOCATION)) {
                if (this.filters.locationId && group.location && this.filters.locationId !== group.location.id) {
                    return false;
                }
            }

            if (this.isFilterEnabled(EventFilterType.CATEGORY)) {
                if (this.filters.category && group.category && this.filters.category !== group.category) {
                    if (this.filters.category === Category.AALL) {
                        if (!allACategories.includes(group.category)) {
                            return false;
                        }
                    }
                    if (this.filters.category !== Category.AALL) {
                        if (this.filters.category !== group.category) {
                            return false;
                        }
                    }
                }
            }

            if (this.isFilterEnabled(EventFilterType.GEARBOX)) {
                if (this.filters.gearbox && group.gearbox && this.filters.gearbox !== group.gearbox) {
                    return false;
                }
            }

            return true;
        });
    }

    @Watch('studyTypeId')
    private studyTypeIdChanged() {
        this.filters = {
            language: null,
            locationId: null,
            category: null,
            gearbox: null
        };
        registrationState.setCurrentStudyType(this.studyTypeId);
        this.getStudyGroups();
    }

    mounted() {
        registrationState.setShowMenu(true);
        registrationState.setCurrentStudyType(this.studyTypeId);
        this.getStudyGroups();
    }

    private getStudyGroups() {
        registrationState.getStudyGroups({
            studyTypeId: this.studyTypeId,
            companyIds: this.companyIdsToFetchGroupsFor
        });
    }

    private isFilterEnabled(filterType: EventFilterType): boolean {
        return this.studyType?.enabledFilters.includes(filterType) ?? false;
    }

    private openRegistrationFormDialog(selectedEvent: StudyGroupResponse) {
        this.selectedEvent = selectedEvent;
        this.dialog = true;
    }
}
