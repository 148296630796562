












































































































import DateUtils from '@/mixins/DateUtils';
import { StudyGroupResponse } from '@/types';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class EventCard extends Mixins(DateUtils) {

    @Prop({ type: Object, required: true })
    private readonly event!: StudyGroupResponse;

    @Prop({ type: String, required: true })
    private readonly color!: string;

    @Prop({ type: String, default: 'white' })
    private readonly textColor!: string;

    private show: boolean = false;

    get iconSize(): number {
        return this.$vuetify.breakpoint.smAndUp ? 32 : 24;
    }
}
