import axios from '@/config/axios';
import { LocationResponse, RegistrationByPersonalCodeRequest, RegistrationFullFormRequest, RegistrationToDrivingLessonRequest, StudyGroupResponse, StudyGroupSearchRequest, StudyTypeResponse } from '@/types';
import { Company } from '@/types/enums';
import { AxiosResponse } from 'axios';

export async function getStudyGroups(studyGroupSearchRequest: StudyGroupSearchRequest): Promise<AxiosResponse<StudyGroupResponse[]>> {
    return axios.post('/study-groups', studyGroupSearchRequest);
}

export async function getStudyTypes(companyId: Company): Promise<AxiosResponse<StudyTypeResponse[]>> {
    return axios.get('/types', {
        params: {
            companyId: companyId
        }
    });
}

export async function getLocations(): Promise<AxiosResponse<LocationResponse[]>> {
    return axios.get('/locations');
}

export async function saveRegistrationFullForm(registrationFullFormRequest: RegistrationFullFormRequest): Promise<AxiosResponse<void>> {
    return axios.post('/save/full', registrationFullFormRequest);
}

export async function saveRegistrationByPersonalCode(registrationByPersonalCodeRequest: RegistrationByPersonalCodeRequest): Promise<AxiosResponse<void>> {
    return axios.post('/save/personal-code', registrationByPersonalCodeRequest);
}

export async function saveRegistrationToDrivingLesson(registrationToDrivingLessonRequest: RegistrationToDrivingLessonRequest): Promise<AxiosResponse<void>> {
    return axios.post('/save/driving-lesson', registrationToDrivingLessonRequest);
}
