











































































import { saveRegistrationFullForm } from '@/api/registration';
import RegistrationFormContainer from '@/components/forms/RegistrationFormContainer.vue';
import CompanySpecific from '@/mixins/CompanySpecific';
import DateUtils from '@/mixins/DateUtils';
import RegistrationFormMixin from '@/mixins/RegistrationFormMixin';
import { RegistrationForm, TranslationValueTextFunction } from '@/types';
import { latinOnlyRegex, personalCodeRegex } from '@/utils/formValidators';
import { get } from 'lodash';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { maxLength, required, requiredUnless } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';

@Component({
    methods: { get },
    components: { RegistrationFormContainer },
    mixins: [validationMixin]
})
export default class RegistrationFullFormDialog extends Mixins(RegistrationFormMixin, CompanySpecific, DateUtils) {

    @Prop({ type: String, required: false })
    private readonly personalCode?: string | null;

    @Validate({
        firstName: {
            required,
            maxLength: maxLength(255),
            latinOnlyRegex
        },
        lastName: {
            required,
            maxLength: maxLength(255),
            latinOnlyRegex
        },
        personalCode: {
            required,
            maxLength: maxLength(255),
            personalCodeRegex
        },
        email: {
            required,
            maxLength: maxLength(255)
        },
        phone: {
            required,
            maxLength: maxLength(255)
        },
        gearbox: {
            required: requiredUnless(function (this: RegistrationFullFormDialog) {
                return this.isFinalStageLSGroup || this.isACategory;
            })
        }
    })
    private registrationForm: RegistrationForm = {
        firstName: null,
        lastName: null,
        personalCode: null,
        email: null,
        phone: null,
        gearbox: null
    }

    get firstNameErrorMessages(): string[] {
        if (!this.$v.registrationForm.firstName?.$dirty) return [];
        if (!this.$v.registrationForm.firstName?.required) return ['Имя не может быть пустым'];
        if (!this.$v.registrationForm.firstName?.maxLength) return ['Имя может быть максимум 255 символов'];
        if (!this.$v.registrationForm.firstName?.latinOnlyRegex) return ['Пожалуйста, используйте только латинские буквы'];
        return [];
    }
    get lastNameErrorMessages(): string[] {
        if (!this.$v.registrationForm.lastName?.$dirty) return [];
        if (!this.$v.registrationForm.lastName?.required) return ['Фамилия не может быть пустой'];
        if (!this.$v.registrationForm.lastName?.maxLength) return ['Фамилия может быть максимум 255 символов'];
        if (!this.$v.registrationForm.lastName?.latinOnlyRegex) return ['Пожалуйста, используйте только латинские буквы'];
        return [];
    }
    get personalCodeErrorMessages(): string[] {
        if (!this.$v.registrationForm.personalCode?.$dirty) return [];
        if (!this.$v.registrationForm.personalCode?.required) return ['Личный код не может быть пустым'];
        if (!this.$v.registrationForm.personalCode?.maxLength) return ['Личный код может быть максимум 255 символов'];
        if (!this.$v.registrationForm.personalCode?.personalCodeRegex) return ['Неверный личный код'];
        return [];
    }
    get emailErrorMessages(): string[] {
        if (!this.$v.registrationForm.email?.$dirty) return [];
        if (!this.$v.registrationForm.email?.required) return ['E-mail не может быть пустым'];
        if (!this.$v.registrationForm.email?.maxLength) return ['E-mail может быть максимум 255 символов'];
        return [];
    }
    get phoneErrorMessages(): string[] {
        if (!this.$v.registrationForm.phone?.$dirty) return [];
        if (!this.$v.registrationForm.phone?.required) return ['Телефон не может быть пустым'];
        if (!this.$v.registrationForm.phone?.maxLength) return ['Телефон может быть максимум 255 символов'];
        return [];
    }
    get gearboxErrorMessages(): string[] {
        if (!this.$v.registrationForm.gearbox?.$dirty) return [];
        if (!this.$v.registrationForm.gearbox?.required) return ['Коробка передач не может быть пустой'];
        return [];
    }

    @Watch('personalCode', {
        immediate: true
    })
    private personalCodeChanged() {
        if (this.personalCode) {
            this.registrationForm.personalCode = this.personalCode;
        }
    }

    private async submit() {
        this.$v.$touch();
        if (this.$v.$invalid || this.isSubmitting) {
            return;
        }

        this.isSubmitting = true;
        try {
            await saveRegistrationFullForm({
                firstName: this.registrationForm.firstName!,
                lastName: this.registrationForm.lastName!,
                personalCode: this.registrationForm.personalCode!,
                email: this.registrationForm.email!,
                phone: this.registrationForm.phone!,
                gearbox: this.registrationForm.gearbox!,
                studyGroupId: this.event!.id as number
            });

            if (process.env.NODE_ENV === 'production') {
                this.$gtm.trackEvent({ event: 'reg_autokool' });
                window.fbq('track', 'Lead');
            }

            this.$notify({
                text: this.$t('form.success') as string,
                type: 'success',
                data: {
                    isRegistration: true
                }
            });
            this.reset();
        } finally {
            this.isSubmitting = false;
        }
    }

    private reset() {
        this.registrationForm = {
            firstName: null,
            lastName: null,
            personalCode: null,
            email: null,
            phone: null,
            gearbox: null
        };
        this.$v.$reset();

        this.dialog = false;
        this.$emit('input', false);
    }

    private translate(item: TranslationValueTextFunction) {
        return item.text();
    }
}
