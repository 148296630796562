import { filtersState } from '@/store/modules/filters';
import { EventFilters } from '@/types';
import { Category, Gearbox, Language } from '@/types/enums';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';

@Component
export default class Filters extends Vue {

    public filters: EventFilters = {
        language: null,
        category: null,
        gearbox: null,
        locationId: null
    }

    @Watch('filters', {
        deep: true
    })
    private filtersChanged() {
        filtersState.setFilters(this.filters);
        this.setUrlQuery();
    }

    created() {
        this.setFiltersFromUrl();
    }

    private setUrlQuery() {
        const query: Dictionary<string | (string | null)[] | null | undefined> = {};

        if (this.filters.language) {
            query.language = this.filters.language;
        }
        if (this.filters.category) {
            query.category = this.filters.category;
        }
        if (this.filters.gearbox) {
            query.gearbox = this.filters.gearbox;
        }
        if (this.filters.locationId) {
            query.location = this.filters.locationId.toString();
        }

        try {
            this.$router.replace({
                name: this.$route.name ?? undefined,
                params: { locale: this.$route.params.locale },
                query: query
            });
            // eslint-disable-next-line no-empty
        } catch {}
    }

    private setFiltersFromUrl() {
        if (this.$route.query.language) {
            this.filters.language = Object.values(Language).find((language: string) => {
                return language.toLowerCase() === (<string>this.$route.query.language).toLowerCase();
            });
        }
        if (this.$route.query.category) {
            this.filters.category = Object.values(Category).find((category: string) => {
                return category.toLowerCase() === (<string>this.$route.query.category).toLowerCase();
            });
        }
        if (this.$route.query.gearbox) {
            this.filters.gearbox = Object.values(Gearbox).find((gearbox: string) => {
                return gearbox.toLowerCase() === (<string>this.$route.query.gearbox).toLowerCase();
            });
        }
        if (this.$route.query.location) {
            const parsed: number = parseInt(<string>this.$route.query.location);
            this.filters.locationId = !isNaN(parsed) ? parsed : null;
        }
    }
}
