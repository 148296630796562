import { categories, gearboxes, languages, visibleSkyAutokoolCategories } from '@/config/constants';
import { determineLocale, Locale, locales } from '@/config/i18n';
import { registrationState } from '@/store/modules/registration';
import { StudyTypeMenuBlock, StudyTypeResponse, TranslationValueTextFunction } from '@/types';
import { Category, Company, EventFilterType, RegistrationFormType } from '@/types/enums';
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router/types/router';

@Component
export default class Commons extends Vue {

    protected dialog: boolean = false;
    protected readonly EventFilterType: typeof EventFilterType = EventFilterType;
    protected readonly RegistrationFormType: typeof RegistrationFormType = RegistrationFormType;
    protected readonly Category: typeof Category = Category;
    protected readonly Company: typeof Company = Company;

    get studyTypes(): StudyTypeResponse[] {
        return registrationState.visibleStudyTypes;
    }

    get studyTypeMenuBlocks(): StudyTypeMenuBlock[] {
        return [
            {
                blockIndex: 1,
                blockName: 'menu.block.1',
                studyTypes: this.studyTypes.filter((studyType: StudyTypeResponse) => studyType.blockIndex === 1)
            },
            {
                blockIndex: 2,
                blockName: 'menu.block.2',
                studyTypes: this.studyTypes.filter((studyType: StudyTypeResponse) => studyType.blockIndex === 2)
            },
            {
                blockIndex: 3,
                blockName: 'menu.block.3',
                studyTypes: this.studyTypes.filter((studyType: StudyTypeResponse) => studyType.blockIndex === 3)
            }
        ];
    }

    get languages(): TranslationValueTextFunction[] {
        return languages;
    }

    get categories(): TranslationValueTextFunction[] {
        return categories;
    }

    get visibleSkyAutokoolCategories(): TranslationValueTextFunction[] {
        return visibleSkyAutokoolCategories;
    }

    get gearboxes(): TranslationValueTextFunction[] {
        return gearboxes;
    }

    get locales(): TranslationValueTextFunction[] {
        return locales;
    }

    get studyTypeLabelByLocale(): string {
        switch (this.$root.$i18n.locale) {
            case 'ru':
                return 'labelRus';
            case 'et':
                return 'labelEst';
            case 'en':
                return 'labelEng';
            default :
                return 'labelEst';
        }
    }

    public buildRouteByStudyTypeId(studyTypeId: number): Location {
        const studyType: StudyTypeResponse | undefined = registrationState.studyTypeById(studyTypeId);
        return studyType ? this.buildRoute(studyType) : { name: 'select-type' };
    }

    public buildRoute(studyType: StudyTypeResponse): Location {
        return {
            name: 'study-groups',
            params: {
                company: this.$route.params.company,
                studyTypeIdAndName: `${studyType.id}-${studyType.labelEng.toLowerCase().replace(/ /g, '-')}`
            }
        };
    }

    public getLabelByLocale(studyType: StudyTypeResponse): string {
        switch (determineLocale()) {
            case Locale.ET:
                return studyType.labelEst;
            case Locale.EN:
                return studyType.labelEng;
            case Locale.RU:
                return studyType.labelRus;
            default:
                return studyType.labelEst;
        }
    }

    public translate(item: TranslationValueTextFunction) {
        return item.text();
    }
}
